import React, { useState } from "react";
import * as s from "./styles/globalStyles";
import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalFonts from "./styles/fonts";
import { ResponsiveWrapper } from "./styles/Container.style";
import { StyledLogo } from "./styles/Image.style";
import ConnectWalletPage from "./pages/ConnectWallet";
import HoldersOverlay from "./components/HoldersOverlayComponent";
import { useSelector } from "react-redux";
import { updateOverlay } from "./redux/data/dataActions";
import ClaimLootPage from "./pages/ClaimLootPage";
import AvailableLootPage from "./pages/AvailableLootPage";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  // const data = useSelector((state) => state.data);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });

  return (
    <>
      <>
        <HoldersOverlay></HoldersOverlay>
      </>
      <s.Screen>
        <GlobalFonts />
        <s.Container flex={1} jc={"center"} ai={"center"}>
          <s.HeaderContainer jc={"center"} ai={"center"}>
            <center>
              <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
            </center>
          </s.HeaderContainer>
          <ResponsiveWrapper
            flex={2}
            style={{
              width: "100%",
              display: "block",
            }}>
            <s.SpacerLarge />

            <center>
              {error !== null && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    width: "50%",
                  }}>
                  {error}
                </div>
              )}
            </center>
            <div>
              {Number(1) >= 2 ? (
                <>
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}>
                    The sale has ended.
                  </s.TextTitle>
                  <s.SpacerSmall />
                </>
              ) : (
                <>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <ConnectWalletPage />
                  ) : (
                    <>
                      {hasClaimed !== false ? (
                        <ClaimLootPage />
                      ) : (
                        <AvailableLootPage
                          setHasClaimed={setHasClaimed}
                          setError={setError}
                        />
                      )}
                    </>
                  )}
                  {/* {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <ConnectWalletPage />
                ) : (
                  <AvailableLootPage />
                )} */}
                </>
              )}
            </div>
          </ResponsiveWrapper>
          {/* 
        footer ref
        https://codesandbox.io/s/footer-with-styled-components-3618y?file=/src/index.js:111-223 
        */}
          <s.Footer>
            <s.FooterTabbedButton
              onClick={(e) => {
                e.preventDefault();

                if (isMobile) {
                  window.open("./config/images/HOLDERS_CHART.png", "_blank");
                } else {
                  dispatch(updateOverlay(true));
                }
              }}>
              <FontAwesomeIcon
                icon={faArrowPointer}
                // size="2xl"
                // transform="calc(50px + 2vmin)"
                color="black"
                style={{ paddingRight: "10px" }}
                // className="position-relative w-25 align-self-center mx-auto "
              />
              Eligibility Chart
            </s.FooterTabbedButton>
            <center className="py-5">
              <p
                style={{
                  fontFamily: `"coder","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
                }}>
                Terms and Conditions:{" "}
                <a href={"https://www.beansnfts.io/terms"}>
                  beansnfts.io/terms
                </a>
              </p>
              <div
                style={{
                  padding: "20px",
                  color: "#FFFFFF",
                  fontSize: "25px",
                }}></div>
              <div>
                <a href={"https://www.playsidestudios.com/"}>
                  <img
                    src={"./config/images/PLY_LOGO_INV.png"}
                    alt={"Meaningful alt text"}
                    style={{
                      height: "2rem",
                      marginRight: "60px",
                    }}></img>
                </a>
                <a href="https://www.dumbwaystodie.com/mobile-games/">
                  <img
                    src={"./config/images/DWTD_LOGO_INV.png"}
                    alt={"Meaningful alt text"}
                    style={{ height: "2rem", marginRight: "0px" }}></img>
                </a>
              </div>
            </center>
          </s.Footer>
        </s.Container>
      </s.Screen>
    </>
  );
}

export default App;
