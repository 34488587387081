import styled from "styled-components";

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ErrorWrapper = styled.div`
  border: 2px solid transparent;
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  font-family: "coder";
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  border-top-color: #e4b9c0;
  color: #843534;
`;
