import styled, { keyframes, css } from "styled-components";

export const StyledButtonContainer = keyframes`
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-5px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
`;

export const backgroundKeyframes = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const lootItemDiv = styled.div`
  transition: all 0.2s ease-in-out;
`;

export const lootItemDivHover = styled.div`
  transition: all 0.5s ease-out;
  &:hover {
    transition: a;; 0.5s ease-out;
    transform: scale(1.1);
  }
`;

export const media = {
  desktop: (...args) => css`
    @media (min-width: 870px) {
      ${css(...args)};
    }
  `,
};

export const Footer = styled.footer`
  text-align: center;
  font-family: "Dumb Ways";
  color: #000000;
  background-color: #feea4f !important;
  width: 100%;
  // min-height: 25vh;
  // max-height: 25%;
  margin-top: auto;
  ${media.desktop`
    // text-align: left;
  `}
  transition: all 0.2s ease-in-out;
`;

export const availabilityChartOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;

  // width: 100%;
  // min-height: 100vh;
  // position: absolute;
  // max-height: 500%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HoldersChartImage = styled.img`
  // object-fit: contain;
  // max-width: 80%;
  // height: "auto";
  // max-height: "80vh";
  // display: flex;
  // flex-flow: row nowrap;
  // align-items: center;

  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
  max-height: 80vh;
  min-height: 80px;
  min-width: 200px;
  width: "auto";
  display: flex;
  align-items: center;
  box-sizing: content-box;
`;

export const holdersChartImgParent = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  flex-direction: row;

  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  justify-content: center;

  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  align-items: center;
`;

export const FooterTabbedButton = styled.div`
  position: relative;
  float: right;
  background-color: #feea4f;
  right: -5px;
  top: -30px;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 5px;
  cursor: pointer;
`;

export const BottomBar = styled.div`
  max-width: 1280px;
  background-color: #feea4f;
  margin: auto;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  ${media.desktop`
    flex-wrap: nowrap;
    padding: 40px 80px 60px;
    justify-content: space-between;
  `};
`;

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #ffffff;
  // background-color: #ff2390;
  // background: rgb(#00d4ff, #60cdff, #12418c);
  // background: -moz-linear-gradient(-45deg, #00d4ff, #60cdff, #12418c, #23d5ab);
  // background: -webkit-linear-gradient(
  //   -45deg,
  //   #00d4ff,
  //   #60cdff,
  //   #12418c,
  //   #23d5ab
  // );
  // background: linear-gradient(
  //   135deg,
  //   rgba(26, 188, 156, 1) 0%,
  //   rgba(142, 68, 173, 1) 100%
  // );
  // background: linear-gradient(-45deg, #00d4ff, #60cdff, #12418c, #23d5ab);
  // background-size: 400% 400%;
  // animation: ${backgroundKeyframes} 15s ease infinite;
  // background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  // background-size: cover;
  // background-position: center;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Dumb Ways";
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
  font-family: "Dumb Ways";
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
  font-family: "Dumb Ways";
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
  font-family: "Dumb Ways";
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
  font-family: "Dumb Ways";
`;

export const HeaderContainer = styled.div`
  width: 100%;
  min-height: 20vh;
  max-height: 20%;
  background-color: #4ac0ff;
  // background-color: #000000;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  font-family: "Dumb Ways";
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  // font-family: "Crunold-Regular";
  font-family: "Dumb Ways";
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  font-family: "Dumb Ways";
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  font-family: "Dumb Ways";
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-family: "Dumb Ways";
`;

export const styledBox = (props) => styled.div`
  opacity: ${props.opacity};
  transition: "opacity 0.5s ease-out";
`;
