import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "../styles/globalStyles";
import { connect } from "../redux/blockchain/blockchainActions";
// import { fetchData } from "../redux/data/dataActions";
import { SpinStretch } from "react-cssfx-loading";
// import { fetchData } from "../redux/data/dataActions";

const ConnectWalletPage = () => {
  const dispatch = useDispatch();

  const blockchain = useSelector((state) => state.blockchain);
  const errorMsg = useSelector((state) => state.blockchain.errorMsg);

  return (
    <>
      <s.Container ai={"center"} jc={"center"}>
        <s.SpacerSmall />
        {blockchain.loading !== true ? (
          <>
            <img
              src="./config/images/connectwallet.png"
              alt="connect"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
              }}
              style={{
                width: "320px",
                objectFit: "scale-down",
              }}
            />
            <s.SpacerLarge />
            <center>
              {errorMsg !== null && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    width: "100%",
                    fontSize: "15px",
                  }}>
                  {errorMsg}
                </div>
              )}
            </center>
          </>
        ) : (
          <>
            <SpinStretch
              color="#000"
              style={{
                display: "inline-block",
              }}
            />
          </>
        )}
      </s.Container>
    </>
  );
};

export default ConnectWalletPage;
