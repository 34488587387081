import {
  faBan,
  faBookmark,
  faCheckSquare,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, Shimmer } from "react-shimmer";
import React from "react";

const AvailableLootComponent = (props) => {
  const lockIcon = (
    <FontAwesomeIcon
      icon={faBan}
      size="2xl"
      // transform="calc(50px + 2vmin)"
      color="white"
      className="position-relative w-25 align-self-center mx-auto "
    />
  );

  const checkIcon = (
    <FontAwesomeIcon
      icon={faCheckSquare}
      size="2xl"
      color="white"
      style={{
        maringBottom: "5px",
        transition: "all 0.5s ease-out",
        opacity: props.isSelected ? "100%" : "0%",
      }}
    />
  );

  const claimIcon = (
    <FontAwesomeIcon
      icon={faBookmark}
      size="2xl"
      color="white"
      style={{
        maringBottom: "5px",
        transition: "all 0.5s ease-out",
        opacity: props.isSelected ? "100%" : "0%",
      }}
    />
  );

  const uncheckedIcon = (
    <FontAwesomeIcon
      icon={faSquare}
      size="2xl"
      color="white"
      style={{
        maringBottom: "5px",

        transition: "all 0.5s ease-out",
      }}
    />
  );

  return (
    <div className="card mb-4 box-shadow">
      <Image
        className="card-img"
        src={props.item.IMAGE_URL}
        alt="Card"
        style={{
          outline: "5px solid white",
        }}
        fallback={<Shimmer width={164} height={164} fadeIn={true} />}
      />

      <div className="card-img-overlay">
        {props.isClaimed ? (
          <div>{claimIcon}</div>
        ) : (
          <>
            <div>{props.isDisabled === true && <div>{lockIcon}</div>}</div>

            <div className="d-flex justify-content-between">
              {props.isSelected && <div>{checkIcon}</div>}
              {props.isSelected === false && !props.isDisabled && (
                <div>{uncheckedIcon}</div>
              )}
              {/* <div>{statusIcon}</div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AvailableLootComponent;
