import styled, { keyframes } from "styled-components";

export const ClaimButtonAnimation = keyframes`
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-5px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 28px;
  border: none;
  background: linear-gradient(20deg, hsl(318, 72%, 73%), hsl(276, 83%, 75%));
  padding: 15px;
  font-size: 35px;
  font-weight: bold;
  font-family: "Dumb Ways";
  color: white;
  width: 450px;
  height: 85px;
  margin-top: 40px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ClaimStyledButton = styled.button`
  border-radius: 28px;
  border: none;
  // background: linear-gradient(20deg, hsl(318, 72%, 73%), hsl(276, 83%, 75%));
  background: rgb(156, 188, 26);
  background: -moz-linear-gradient(
    45deg,
    rgba(238, 162, 199, 1) 0%,
    rgba(249, 211, 190, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(238, 162, 199, 1) 0%,
    rgba(249, 211, 190, 1) 100%
  );
  background: linear-gradient(
    200deg,
    rgba(238, 162, 199, 1) 0%,
    rgba(249, 211, 190, 1) 100%
  );
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
  font-family: "Dumb Ways";
  color: white;
  width: 400px;
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
  transform: translatey(0px);
  // transition: transform 0.5s ease-in-out;
  animation: ${ClaimButtonAnimation} 4s ease-in-out infinite;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ClaimButtonWrapper = styled.div`
  &:hover {
    // transition: all 0.5s ease-in-out;
    // transform: scale(1.1);
  }
`;
