// constants
import Web3EthContract from "web3-eth-contract";
import detectEthereumProvider from "@metamask/detect-provider";
import { api } from "../../config";
// import { ethers } from "ethers";
import Web3 from "web3";
// log
// import { fetchData } from "../data/dataActions";

const setClaimedLoot = (payload) => {
  return {
    type: "SET_CLAIMED_LOOT",
    payload,
  };
};

const connectRequest = (payload) => {
  return {
    type: "CONNECTION_REQUEST",
    payload,
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const claimLoot = (indexes) => {
  return (dispatch) => {
    dispatch(
      setClaimedLoot({
        claimedIndexes: indexes || [],
      })
    );
  };
};

// function hasEthereum() {
//   const { ethereum } = window;
//   if (ethereum && ethereum.isMetaMask) {
//     return true;
//   } else {
//     return false;
//   }
// }

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    try {
      let CONFIG;
      let abi;
      if (api.live) {
        const configReq = await fetch(`${api.host}/v1/config/lootDappConfig`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        CONFIG = await configReq.json();
        CONFIG = CONFIG.value;
        if (!CONFIG) {
          dispatch(
            connectFailed("Failed to pull config from server or local config")
          );
        }
        const abiReq = await fetch(
          `${api.host}/v1/contracts/${CONFIG.CONTRACT_ADDRESS}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const contractDoc = await abiReq.json();
        abi = contractDoc.abi;
        if (!abi) {
          dispatch(connectFailed("Failed to  pull ABI"));
        }
      } else {
        // #region Fetching locally
        const abiResponse = await fetch("/config/abi.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        abi = await abiResponse.json();
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        CONFIG = await configResponse.json();
        // #endregion
      }
      // TODO - work out if mobile is supported and how
      const { ethereum } = window;
      const provider = await detectEthereumProvider();
      const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
      // if (!ethereum) {
      //   window.addEventListener("ethereum#initialized", hasEthereum, {
      //     once: true,
      //   });

      //   // If the event is not dispatched by the end of the timeout,
      //   // the user probably doesn't have MetaMask installed.
      //   setTimeout(hasEthereum, 3000); // 3 seconds
      // }
      if (metamaskIsInstalled) {
        Web3EthContract.setProvider(ethereum);
        let web3 = new Web3(ethereum);
        try {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          const networkId = await ethereum.request({
            method: "net_version",
          });
          console.log(networkId, CONFIG.NETWORK.ID);
          // eslint-disable-next-line eqeqeq
          if (networkId == CONFIG.NETWORK.ID) {
            const SmartContractObj = new Web3EthContract(
              abi,
              CONFIG.CONTRACT_ADDRESS
            );
            let whiteListUser;
            const whitelistReq = await fetch(
              `${api.host}/v1/loot/claim/whitelist/${accounts[0]}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            );
            whiteListUser = await whitelistReq.json();
            console.log("whitelist user", whiteListUser);
            dispatch(
              connectSuccess({
                account: accounts[0],
                smartContract: SmartContractObj,
                web3,
                config: CONFIG,
                availableIndexes: whiteListUser.availableIndexes || [],
                claimedIndexes: whiteListUser.claimedIndexes || [],
              })
            );
            // Add listeners start
            ethereum.on("accountsChanged", (accounts) => {
              dispatch(updateAccountRequest(accounts[0]));
            });
            ethereum.on("chainChanged", () => {
              window.location.reload();
            });
            // Add listeners end
          } else {
            dispatch(
              connectFailed(
                `Change network to ${CONFIG.NETWORK.NAME} in Metamask.`
              )
            );
          }
        } catch (err) {
          console.log(err);
          dispatch(connectFailed(`Something went wrong. ${err.message}`));
          ethereum.enable();
        }
      } else {
        // dispatch(connectFailed("Install Metamask then refresh."));
        dispatch(
          connectFailed(
            `Please install metamask - if you are running on mobile use the metamask app browser`
          )
        );
      }
    } catch (e) {
      dispatch(connectFailed(`Network failed with message '${e.message}'`));
    }
  };
};
