import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { updateOverlay } from "../redux/data/dataActions";
import { useDispatch } from "react-redux";
import * as s from "../styles/globalStyles";

const HoldersOverlay = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  return (
    <s.availabilityChartOverlay
      style={{
        opacity: data.overlay ? "100%" : "0%",
        zIndex: data.overlay ? "2" : "-1",
        transition: "all 0.7s ease-in-out",
      }}>
      <div class="content">
        <s.holdersChartImgParent>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (data.overlay) {
                dispatch(updateOverlay(false));
              }
            }}>
            <s.HoldersChartImage src="./config/images/HOLDERS_CHART.png" />
          </OutsideClickHandler>
          <div class="cell"></div>
        </s.holdersChartImgParent>
      </div>
    </s.availabilityChartOverlay>
  );
};

export default HoldersOverlay;
