// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const toggleOverlay = (payload) => {
  return {
    type: "TOGGLE_OVERLAY",
    payload: payload,
  };
};

export function updateOverlay(payload) {
  return (dispatch) => {
    dispatch(toggleOverlay(payload));
  };
}

export function attemptEstimate() {
  // return async (dispatch) => {
  //   try {
  //     const currentState = store.getState();
  //     const blockchain = currentState.blockchain;
  //     const availIndexes = blockchain.availableIndexes;
  //     const web3 = blockchain.web3;
  //     const indexAmounts = [];
  //     for (let i = 0; i < availIndexes.length; i += 1) {
  //       indexAmounts.push(1);
  //     }
  //     const transactionNonce = await web3.eth.getTransactionCount(
  //       blockchain.account,
  //       "latest"
  //     );
  //     const data = blockchain.smartContract.methods
  //     .mintClaim(
  //       availIndexes,
  //       verificationRes.indexAmounts,
  //       // [1, 2, 3, 4, 5, 6, 7, 8],
  //       // [2, 2, 2, 2, 2, 2, 2, 2],
  //       blockchain.config.NETWORK.NAME.toLowerCase(),
  //       blockchain.config.CONTRACT_ADDRESS.toLowerCase(),
  //       1,
  //       verificationRes.signature
  //     )
  //     .encodeABI();
  //   // Gas is in gwei
  //   let gas;
  //     gas = await web3.eth.estimateGas({
  //       from: blockchain.account,
  //       nonce: transactionNonce,
  //       to: blockchain.config.CONTRACT_ADDRESS,
  //       data: data,
  //     });
  //   } catch (e) {
  //     console.log("estimate ERROR: ", e);
  //   }
  // }
}

export function verifySignature(tx) {
  return async (dispatch) => {
    try {
      console.log(tx);
      let signature = await store
        .getState()
        .blockchain.smartContract.methods.verify(
          tx.from,
          tx.to,
          tx.amount,
          tx.nonce,
          tx.message,
          tx.signature
        )
        .call();

      console.log(signature);
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };
}

export function fetchData(lootNumber) {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let lootItem = await store
        .getState()
        .blockchain.smartContract.methods.getLootItem(lootNumber)
        .call();

      const totalSupply = lootItem.TotalSupply;
      const cost = lootItem.Cost;
      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
        })
      );
    } catch (err) {
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
}
