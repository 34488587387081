import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AvailableLootComponent from "../components/AvailableLootComponent";
import * as s from "../styles/globalStyles";
import { api } from "../config";
import { claimLoot as reduxSetClaimedLoot } from "../redux/blockchain/blockchainActions";
import { ClaimStyledButton, ClaimButtonWrapper } from "../styles/Button.style";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SpinStretch } from "react-cssfx-loading";

const AvailableLootPage = (props) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  // const localEndpoint = "http://localhost:7070/";
  // const signatureRoute = "api/v1/web3/verifysigunprotected";
  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });
  const [claimError, setClaimError] = useState(null);
  const [deselected, setDeselected] = useState([]);
  const [availableLoot, setAvailableLoot] = useState([]);
  const [claimedLoot, setClaimedLoot] = useState([]);
  const [personalSig, setPersonalSig] = useState(null);
  const [notice, setNotice] = useState(null);

  const [localSignature, setLocalSignature] = useState(null);
  const [isLoadingSignature, setLoadingSignature] = useState(false);
  const [estimatedGas, setEstimatedGas] = useState(null);
  const [claimData, setClaimData] = useState(null);
  const [transReceipt, setTransReceipt] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    blockchain.config.FOUNDERS_LOOT.sort((a, b) =>
      blockchain.availableIndexes.includes(a.CONTRACT_INDEX) ? -1 : 1
    );
    console.log("blockchain form avil loot", blockchain);
    setAvailableLoot(blockchain.availableIndexes || []);
    setClaimedLoot(blockchain.claimedIndexes || []);
    console.log(blockchain.claimedIndexes);
    setClaimedLoot(blockchain.claimedIndexes);
    console.log(claimedLoot, claimData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifySignature = async (blockchain) => {
    setLoadingSignature(true);
    setClaimError(null);
    const web3 = blockchain.web3;
    let nonce;
    let nonceReq;
    try {
      nonceReq = await fetch(`${api.host}/v1/wallets/nonce`, {
        method: "post",
        body: JSON.stringify({
          wallet: blockchain.account,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (e) {
      console.log("nonceReq err", e);
      setClaimError("Failed to generate nonce");
      setLoadingSignature(false);
      return;
    }
    nonce = await nonceReq.json();
    if (!nonce) {
      setClaimError("Failed to generate nonce");
      setLoadingSignature(false);
      return;
    }
    nonce = nonce.code;
    console.log(nonce);
    let personalSignature;
    try {
      personalSignature = await web3.eth.personal.sign(
        nonce.toString(),
        blockchain.account
      );
    } catch (e) {
      console.log("metamask sign err");
      setClaimError("Metamask signature verification was declined");
      setLoadingSignature(false);
      return;
    }
    setPersonalSig(`${nonce.toString()},${personalSignature}`);
    const selectedLoot = [];
    const selectedLootAmount = [];
    // eslint-disable-next-line array-callback-return
    blockchain.config.FOUNDERS_LOOT.map((d) => {
      if (
        !deselected.includes(d.CONTRACT_INDEX) &&
        availableLoot.includes(d.CONTRACT_INDEX)
      ) {
        selectedLoot.push(d.CONTRACT_INDEX);
        selectedLootAmount.push(1);
      }
    });
    let verificationRes;
    let signatureReq;
    console.log("selected loot", selectedLoot);
    try {
      signatureReq = await fetch(`${api.host}/v1/loot/claim/whitelist/verify`, {
        method: "post",
        body: JSON.stringify({
          wallet: blockchain.account,
          walletSignature: personalSignature,
          selectedIndexes: selectedLoot,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (e) {
      console.log("nonceReq err", e);
      setClaimError("Failed to generate nonce");
      setLoadingSignature(false);
      return;
    }
    verificationRes = await signatureReq.json();
    console.log("verification res", verificationRes);
    if (!verificationRes.signature || verificationRes.signature === "0x0") {
      console.log(verificationRes);
      setClaimError(
        "Your generated nonce may be invalid or your wallet is inelligable for any loot"
      );
      setLoadingSignature(false);
      return;
    }

    // alert(`Server signature result ${verificationRes.signature}`);
    const transactionNonce = await web3.eth.getTransactionCount(
      blockchain.account,
      "latest"
    );
    console.log("transaction nonce", transactionNonce);
    const data = blockchain.smartContract.methods
      .mintClaim(
        verificationRes.finalSelectedIndexes,
        verificationRes.indexAmounts,
        // [1, 2, 3, 4, 5, 6, 7, 8],
        // [2, 2, 2, 2, 2, 2, 2, 2],
        blockchain.config.NETWORK.ID,
        blockchain.config.CONTRACT_ADDRESS.toLowerCase(),
        1,
        verificationRes.signature
      )
      .encodeABI();
    // Gas is in gwei
    let gas;
    try {
      gas = await web3.eth.estimateGas({
        from: blockchain.account,
        nonce: transactionNonce,
        to: blockchain.config.CONTRACT_ADDRESS,
        data: data,
      });
    } catch (e) {
      console.log("gas estimate failed with ", e);
      setClaimError(
        "The transaction will likely fail, we recommend you dont attempt to claim"
      );
      setLoadingSignature(false);
      return;
    }
    setClaimData(data);
    setEstimatedGas(gas);
    setLocalSignature(verificationRes.signature);
    setLoadingSignature(false);
  };

  const claimLoot = async (blockchain) => {
    try {
      setLoading(true);
      setClaimError(null);
      setNotice(null);
      const web3 = blockchain.web3;
      // get latest nonce.

      // const selectedLoot = [];
      // const selectedLootAmount = [];
      // // eslint-disable-next-line array-callback-return
      // blockchain.config.FOUNDERS_LOOT.map((d) => {
      //   if (
      //     !deselected.includes(d.CONTRACT_INDEX) &&
      //     availableLoot.includes(d.CONTRACT_INDEX)
      //   ) {
      //     selectedLoot.push(d.CONTRACT_INDEX);
      //     selectedLootAmount.push(1);
      //   }
      // });
      const [serverNonce, sig] = personalSig.split(",");
      // const data = blockchain.smartContract.methods
      //   .mintClaim(
      //     selectedLoot,
      //     selectedLootAmount,
      //     // [1, 2, 3, 4, 5, 6, 7, 8],
      //     // [2, 2, 2, 2, 2, 2, 2, 2],
      //     blockchain.config.NETWORK.ID,
      //     blockchain.config.CONTRACT_ADDRESS.toLowerCase(),
      //     1,
      //     sig
      //   )
      //   .encodeABI();

      const gasNonce = await web3.eth.getTransactionCount(
        blockchain.account,
        "latest"
      );
      // console.log(selectedLoot, selectedLootAmount, "with nonce", gasNonce);
      let gas;
      try {
        gas = await web3.eth.estimateGas({
          from: blockchain.account,
          nonce: gasNonce,
          to: blockchain.config.CONTRACT_ADDRESS,
          data: claimData,
        });
      } catch (e) {
        setLoading(false);
        setNotice(null);
        console.log("gas estimate failed with ", e);
        setClaimError(
          "The transaction will likely fail, we recommend you dont attempt to claim"
        );
        return;
      }
      console.log("successful gas estimate", gas);
      const nonce = await web3.eth.getTransactionCount(
        blockchain.account,
        "latest"
      );
      const tx = {
        from: blockchain.account,
        to: blockchain.config.CONTRACT_ADDRESS,
        nonce: nonce,
        gas: estimatedGas,
        data: claimData,
      };
      console.log(tx);
      const signPromise = await web3.eth
        .sendTransaction(tx)
        .catch(function (error) {
          // props.setError("An error has occured while claiming.");
          setClaimError("An error occured while claiming.");
          console.log("failed transaction error", error);
          setLoading(false);
        });
      console.log("PROMISE: ", signPromise);
      let confirmRes;
      let confirmReq;
      let claimedIndexes = [];
      try {
        // eslint-disable-next-line array-callback-return
        blockchain.config.FOUNDERS_LOOT.map((val) => {
          if (
            availableLoot.includes(val.CONTRACT_INDEX) &&
            !deselected.includes(val.CONTRACT_INDEX)
          ) {
            claimedIndexes.push(val.CONTRACT_INDEX);
          }
        });
        claimedIndexes.sort((a, b) => a - b);

        confirmReq = await fetch(
          `${api.host}/v1/loot/claim/whitelist/confirm`,
          {
            method: "post",
            body: JSON.stringify({
              wallet: blockchain.account,
              walletSignature: sig,
              nonce: serverNonce,
              transactionHash: signPromise.transactionHash,
              claimedIndexes: claimedIndexes,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      } catch (e) {
        setClaimError(
          "Failed to notify server of your claimed items, you will still have them!"
        );
        setLoadingSignature(false);
      }
      dispatch(reduxSetClaimedLoot(claimedIndexes));
      confirmRes = await confirmReq.json();
      console.log("confirmed data", confirmRes);
      // alert("Successfully saved", confirmRes);
      setTransReceipt(`${signPromise.transactionHash}`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setClaimError(
        "The transaction failed, please check your elligibility and current inventory. Contact support on discord if issues persist"
      );
    }
  };

  return (
    <div className="album container">
      <div className="container">
        <center>
          {claimError !== null && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                width: "100%",
              }}>
              {claimError}
            </div>
          )}
          {notice !== null && (
            <div
              className="alert alert-info"
              role="alert"
              style={{
                width: "100%",
              }}>
              {notice}
            </div>
          )}
        </center>
        <s.lootItemDiv className="row">
          {blockchain.config.FOUNDERS_LOOT
            // .sort((a, b) =>
            //   availableLoot.includes(a.CONTRACT_INDEX) ? -1 : 1
            // )
            .map((d, index) => {
              return (
                <s.lootItemDivHover
                  className="col-md-2"
                  key={d.CONTRACT_INDEX}
                  onClick={(e) => {
                    e.preventDefault();
                    setNotice(null);
                    if (localSignature == null) {
                      if (availableLoot.includes(d.CONTRACT_INDEX)) {
                        if (
                          blockchain.claimedIndexes.includes(d.CONTRACT_INDEX)
                        ) {
                          setNotice("You have already claimed this item");
                        } else {
                          if (deselected.includes(d.CONTRACT_INDEX)) {
                            setDeselected(
                              deselected.filter(
                                (item) => item !== d.CONTRACT_INDEX
                              )
                            );
                          } else {
                            setDeselected([...deselected, d.CONTRACT_INDEX]);
                          }
                        }
                      }
                    } else {
                      setNotice(
                        "Once you have verified, you cannot change your selection! Please refresh to change selection or continue with claim"
                      );
                    }
                  }}
                  style={{
                    opacity:
                      deselected.includes(d.CONTRACT_INDEX) === true ||
                      availableLoot.includes(d.CONTRACT_INDEX) === false ||
                      blockchain.claimedIndexes.includes(d.CONTRACT_INDEX)
                        ? "50%"
                        : "100%",
                    transition: "all 0.5s ease-out",
                  }}>
                  {/* <div>
                    test {index} {d.CONTRACT_INDEX} {d.NAME}
                  </div> */}
                  <AvailableLootComponent
                    key={d.CONTRACT_INDEX}
                    item={d}
                    isClaimed={blockchain.claimedIndexes.includes(
                      d.CONTRACT_INDEX
                    )}
                    isDisabled={!availableLoot.includes(d.CONTRACT_INDEX)}
                    isSelected={
                      !deselected.includes(d.CONTRACT_INDEX) &&
                      availableLoot.includes(d.CONTRACT_INDEX)
                    }
                  />
                </s.lootItemDivHover>
              );
            })}
        </s.lootItemDiv>
      </div>
      <center>
        {localSignature !== null ? (
          <>
            {transReceipt !== null ? (
              <>
                <ClaimButtonWrapper>
                  <ClaimStyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `https://${
                          blockchain.config.NETWORK.NAME.toLowerCase() ===
                          "rinkeby"
                            ? "rinkeby."
                            : ""
                        }etherscan.io/tx/${transReceipt}`,
                        "_blank"
                      );
                    }}
                    style={{
                      backgroundColor: "#000",
                      background: `linear-gradient(200deg, 
                          rgba(36, 219, 107, 1) 0%,
                          rgba(118, 232, 162, 1) 100%)`,
                      width: isMobile ? "300px" : "400px",
                    }}>
                    {isLoading === true ? (
                      <SpinStretch
                        color="#FFF"
                        style={{
                          display: "inline-block",
                        }}
                      />
                    ) : (
                      <div>
                        Loot claimed!
                        <p style={{ fontSize: "12px" }}>
                          Click here to check on etherscan!
                        </p>
                      </div>
                    )}
                  </ClaimStyledButton>
                </ClaimButtonWrapper>
              </>
            ) : (
              <>
                <ClaimButtonWrapper>
                  <ClaimStyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        blockchain.availableIndexes.sort().join(",") ===
                        blockchain.claimedIndexes.sort().join(",")
                      ) {
                        setNotice("All your loot appears to be claimed");
                      } else {
                        claimLoot(blockchain);
                      }
                    }}
                    style={{
                      backgroundColor: "#000",
                      background:
                        blockchain.availableIndexes.sort().join(",") ===
                        blockchain.claimedIndexes.sort().join(",")
                          ? `linear-gradient(200deg, 
                          rgba(145, 145, 145) 0%,
                          rgba(187, 187, 187, 1) 100%)`
                          : `linear-gradient(200deg, 
                            rgba(254, 164, 1) 0%,
                            rgba(255, 198, 96, 1) 100%)`,
                      width: isMobile ? "300px" : "400px",
                    }}>
                    {blockchain.availableIndexes.sort().join(",") ===
                    blockchain.claimedIndexes.sort().join(",") ? (
                      <div>All loot claimed!</div>
                    ) : (
                      <>
                        {isLoading === true ? (
                          <SpinStretch
                            color="#FFF"
                            style={{
                              display: "inline-block",
                            }}
                          />
                        ) : (
                          <div>Claim Loot!</div>
                        )}
                      </>
                    )}
                  </ClaimStyledButton>
                </ClaimButtonWrapper>
              </>
            )}
          </>
        ) : (
          <ClaimButtonWrapper>
            <ClaimStyledButton
              onClick={(e) => {
                e.preventDefault();
                verifySignature(blockchain);
                // claimLoot(blockchain, CONFIG);
              }}
              style={{
                width: isMobile ? "300px" : "400px",
              }}>
              {isLoadingSignature === true ? (
                <SpinStretch
                  color="#FFF"
                  style={{
                    display: "inline-block",
                    marginTop: "5px",
                  }}
                />
              ) : (
                <div>Verify Wallet</div>
              )}
            </ClaimStyledButton>
          </ClaimButtonWrapper>
        )}

        {/* <center className="py-1">
          {<div
            style={{
              padding: "20px",
              color: "#FFFFFF",
              fontSize: "25px",
            }}></div>}
          <div>
            
            <a href={"https://www.playsidestudios.com/"}>
              <img
                src={"./config/images/PLY_LOGO.png"}
                alt={"Meaningful alt text"}
                style={{ height: "2rem", marginRight: "60px" }}></img>
            </a>
            <a href="https://www.dumbwaystodie.com/mobile-games/">
              <img
                src={"./config/images/DWTD_LOGO.png"}
                alt={"Meaningful alt text"}
                style={{ height: "2rem", marginRight: "0px" }}></img>
            </a>
          </div>
        </center> */}
      </center>

      <center>
        <Container>
          <Row>
            <Col>
              <p
                style={{
                  color: "#4ac0ff",
                  paddingTop: "40px",
                  paddingBottom: "30px",
                  fontSize: "0.8em",
                  fontFamily: `"coder","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
                }}>
                <p>
                  {" "}
                  <p style={{ fontSize: "16px" }}>
                    <p>
                      Active wallet:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {blockchain.account}
                      </span>
                    </p>
                  </p>
                </p>
                You can only claim each item you are eligible for{" "}
                <span style={{ fontWeight: "bold" }}>ONCE</span>. Trying to
                reclaim loot may result in wasted gas.
                <br />
                We recommend you claim all your loot at once to save on gas
                fees! Ensure all available loot are ticked or you can tick the
                ones you want to claim.
              </p>
            </Col>
            {/* <Col>
              <p
                style={{
                  color: "#4ac0ff",
                  paddingTop: "40px",
                  fontSize: "1.5em",
                  fontFamily: `"coder","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
                }}>
                Show loot item criteria
              </p>
            </Col> */}
            {/* <Col>
              <p
                style={{
                  color: "#4ac0ff",
                  paddingTop: "40px",
                  fontSize: "0.8em",
                  fontFamily: `"coder","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
                }}>
                The snapshot was finalized at
                <span style={{ color: "orange", fontWeight: "bold" }}>
                  {" "}
                  DATE HERE
                </span>
                . Any beans purchased
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  after this time
                </span>{" "}
                will not make you eligable to claim more items
              </p>
            </Col> */}
          </Row>
        </Container>
      </center>
    </div>
  );
};

export default AvailableLootPage;
