import { createGlobalStyle } from "styled-components";

import DumbWaysFont from "../styles/Crunold-Regular.woff";

export default createGlobalStyle`
  @font-face {
    font-family: "Dumb Ways";
    src: local("Dumb Ways"), local("Dumb Ways"), url(${DumbWaysFont}) format("woff");
    font-weight: 300;
    font-style: normal;
  }
`;
