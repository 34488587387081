import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "../styles/globalStyles";
// import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import LootComponent from "../components/LootComponent";
import { StyledButton } from "../styles/Button.style";

import abi from "../abi.json";
// import LockedLootComponent from "../components/LockedLootComponent";

const ClaimLootPage = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });

  useEffect(() => {
    dispatch(fetchData(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimLoot = async (blockchain, CONFIG) => {
    let totalMintPrice = String(CONFIG.DISPLAY_COST * 1);

    const web3 = blockchain.web3;
    const activeContract = new web3.eth.Contract(abi, CONFIG.CONTRACT_ADDRESS);
    let totalPrice = totalMintPrice;
    let accounts = await web3.eth.getAccounts();

    const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // get latest nonce.
    // const input = Buffer.from(String(nonce), "hex");

    window.ethereum.request({ method: "eth_requestAccounts" });

    const tx = {
      from: accounts[0],
      to: CONFIG.CONTRACT_ADDRESS,
      nonce: nonce,
      gas: 500000,
      value: web3.utils.toWei(totalPrice).toString(),
      data: activeContract.methods
        // .mintClaim([1], [1], nonce, 1, "test", ax.signature)
        .encodeABI(),
    };

    const signPromise = await web3.eth
      .sendTransaction(tx)
      .catch(function (error) {
        console.log("ERROR: ", error);
      });

    console.log("PROMISE: ", signPromise);
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();

    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          backgroundColor: "var(--primary)",
        }}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <LootComponent
                amount={0}
                amountLeft={data.totalSupply}
                isDisabled={false}
              />
            </div>
            <div className="col-md-4">
              <LootComponent
                amount={0}
                amountLeft={data.totalSupply}
                isDisabled={false}
              />
            </div>
            <div className="col-md-4">
              <LootComponent
                amount={0}
                amountLeft={data.totalSupply}
                isDisabled={false}
              />
            </div>
          </div>
        </div>

        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            claimLoot(blockchain, CONFIG);
          }}>
          <div>Mint for {data.cost} Eth</div>
        </StyledButton>
      </s.Container>
    </s.Screen>
  );
};

export default ClaimLootPage;
