const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  errorMsg: null,
  config: null,
  availableIndexes: null,
  claimedIndexes: null,
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        errorMsg: null,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
        config: action.payload.config,
        availableIndexes: action.payload.availableIndexes,
        claimedIndexes: action.payload.claimedIndexes,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    case "SET_CLAIMED_LOOT":
      return {
        ...state,
        claimedIndexes: action.payload.claimedIndexes,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
