import React from "react";

const LootComponent = (props) => {
  return (
    <>
      <div
        className="card text-white"
        style={{
          width: props.isDisabled ? "90%" : "100%",
          opacity: props.isDisabled ? "75%" : "100%",
        }}
      >
        <img
          className="card-img"
          src={"/config/images/test-image.png"}
          alt="Card"
          style={{
            outline: "4px solid white",
          }}
        />
        <div className="card-img-overlay">
          <h1
            className="card-title opacity-50"
            style={{
              fontFamily: "Crunold-Regular",
            }}
          >
            {props.amount}/{props.amountLeft}
          </h1>
        </div>
      </div>
    </>
  );
};

export default LootComponent;
